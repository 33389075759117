import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "app/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      // variants
      variant: {
        primary: "bg-primary text-primary-foreground hover:bg-primary/90",
        "primary-destructive": "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        "primary-success": "bg-success text-success-foreground hover:bg-success/90",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        "secondary-destructive": "bg-red-100 text-red-600 hover:bg-red-200",
        "secondary-success": "bg-green-100 text-green-600 hover:bg-green-200",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        "outline-destructive":
          "border border-destructive bg-background hover:bg-destructive hover:text-destructive-foreground",
        "outline-success": "border border-success bg-background hover:bg-success hover:text-success-foreground",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        "ghost-destructive": "text-red-600 hover:bg-red-200 hover:text-red-600",
        "ghost-success": "text-green-600 hover:bg-green-200 hover:text-green-600",
        link: "text-primary underline-offset-4 hover:underline",
        "link-destructive": "text-destructive underline-offset-4 hover:underline",
        "link-success": "text-success underline-offset-4 hover:underline",
        "dropdown-item-default":
          "bg-white hover:bg-gray-100 py-1.5 px-2 w-full text-left justify-start focus-visible:ring-0",
      },
      size: {
        default: "h-10 px-4 py-2",
        lg: "h-11 rounded-md px-8",
        sm: "h-9 rounded-md px-3",
        xs: "h-auto px-2 py-1",
        icon: "h-10 w-10",
        "icon-sm": "h-7 w-7 p-1",
      },
      // custom
      // color: {
      //   primary: "",
      //   secondary: "",
      //   destructive: "",
      //   success: "",
      // },
    },

    // compounds (i.e. when both variants are set, apply this className)
    compoundVariants: [
      // coloring default buttons
      // {
      //   variant: "primary",
      //   color: "destructive",
      //   className: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      // },
      // {
      //   variant: "default",
      //   color: "success",
      //   className: "bg-success text-success-foreground hover:bg-success/90",
      // },
    ],

    // default
    defaultVariants: {
      variant: "primary",
      size: "default",
      // color: "default",
    },
  },
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ size, variant, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
